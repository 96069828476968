import useComponentSize from "@rehooks/component-size"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import HTMLRenderer from 'react-html-renderer'
import styled from "styled-components"
import { layout, space } from "styled-system"
import website from "../../config/website"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Video from "../components/video"
import { Flex, FlexItem } from "../elements"
import SocialShare from "../components/SocialShare"

const ImgExtended = styled(Img)`
  object-fit: cover;
  cursor: pointer;
  overflow: hidden;
  ${layout}
`

const Wrapper = styled.div`
  overflow: hidden;
  ${layout};
`

const Description = styled.span`
  font-size: 12px;
  color: #cccccc;
  ${layout};
  ${space};
`

function Media({ image_selected }) {
  if (image_selected.video?.embed_url) {
    return <Video video={image_selected.video} />
  }
  return (
    <ImgExtended
      fluid={image_selected.image.fluid}
      alt={image_selected.image.alt}
    />
  )
}

const Content = (props) => <>{props.children}</>
const Ahref = (props) => (<a href={props.href} target="_blank" rel="noreferrer">{props.children}</a>)

const Project = ({
  data: {
    prismicProject: project,
  },
  location,
  pageContext: {
    previousProjectPath,
    nextProjectPath,
    allUpperDomains,
    breadcrumb,
  },
}) => {
  const { data : {images_group, content, title, meta_description, meta_title} } = project
  const ref = useRef(null)
  const size = useComponentSize(ref)
  const { height } = size
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const description =
    images_group[selectedImageIndex]?.content && images_group[selectedImageIndex].content.html !== ""
      ? images_group[selectedImageIndex].content
      : content
  const desc = meta_description || title.text
  const mainTitle = meta_title || title.text
  
  const newBreadcrumbs = Object.assign({} , breadcrumb);
  newBreadcrumbs.crumbs = breadcrumb.crumbs.slice(0,breadcrumb.crumbs.length - 1)
    
  return (
    <>
      <Layout
        mt="0"
        customSEO
        location={location}
        allUpperDomains={allUpperDomains}
        breadcrumb={newBreadcrumbs}
      >
        <SEO
          title={`${mainTitle} | ${website.titleAlt}`}
          pathname={location.pathname}
          desc={desc}
          node={project}
        />

        <Flex
          flexGrow="1"
          alignItems={["flex-start", "flex-start", "flex-start", "flex-start"]}
          justifyContent="flex-start"
        >
          <Flex
            flexDirection={["column-reverse", "column-reverse", "row", "row"]}
            css="height:100%;width:calc(15vw + 1400px)"
            justifyContent={[
              "flex-end",
              "flex-end",
              "flex-start",
              "flex-start",
            ]}
            alignItems="baseline"
            flexWrap={["no-wrap", "no-wrap", "wrap", "wrap"]}
          >
            <Flex
              alignSelf={[
                "flex-start",
                "flex-start",
                "flex-start",
                "flex-start",
              ]}
              alignItems="baseline"
              order={[2, 2, 0, 0]}
              flexBasis={["auto", "auto", "100%", "100%"]}
              flexDirection={["column", "column", "row", "row"]}
              justifyContent="flex-start"
              ml={["12px", "12px", 0, 0]}
              flexShrink="0"
            >
              <h1 css="margin-right: 12px;min-width: 0;">
                {title.text}{" "}
                <Description
                  mt={["12px", "12px", 0, 0]}
                  display={["block", "block", "inline", "inline"]}
                >                  
                  <HTMLRenderer 
                    html={description.html}
                    components={{
                      p: Content,
                      a: Ahref
                    }}                    
                    />                  
                </Description>
              </h1>
            </Flex>
            <Flex
              alignSelf={[
                "flex-start",
                "flex-start",
                "flex-start",
                "flex-start",
              ]}
              alignItems="flex-start"
              order={[0, 0, 3, 3]}
              flexBasis={[0, 0, "100%", "100%"]}
              flexDirection="row"
              ml={["12px", "12px", 0, 0]}
              mt={["12px", "12px", "12px", "12px"]}
              flexShrink="0"
            >
              {previousProjectPath && (
                <Link css="margin:0 5px" to={`${previousProjectPath}`}>
                  {"< previous project "}
                </Link>
              )}
              {previousProjectPath && nextProjectPath && <>|</>}
              {nextProjectPath && (
                <>
                  <Link css="margin:0 5px" to={`${nextProjectPath}`}>
                    {" next project >"}
                  </Link>
                </>
              )}
              <FlexItem ml="auto">
                <SocialShare
                  url={location.href}
                  media={images_group[selectedImageIndex].image.url}
                  description={`${mainTitle} ${description.text ? `| ${description.text}` : " "}`}
                />
              </FlexItem>
            </Flex>

            <Flex
              css="overflow-x: auto; ::-webkit-scrollbar {display: none;};scrollbar-width: none;"
              maxHeight={height}
              overflowX={["auto", "auto", "hidden", "hidden"]}
              width={["100%", "100%", "15vw", "15vw"]}
              mr={[null, null, "10px", "10px"]}
              flexDirection={["row", "row", "column", "column"]}
              flex={["0 1 1", "0 1 1", "0 1 auto", "0 1 auto"]}
            >
              {images_group.map(({ image }, index) => {
                return (
                  <>
                    {image && (
                      <FlexItem
                        key={`${index}-carousel`}
                        flexGrow={[0, 0, 1, 1]}
                        flexShrink={[0, 0, 1, 1]}
                        width={["40%", "40%", "100%", "100%"]}
                        mb="5px"
                        mt={["5px", "5px", "0", "0"]}
                        mr={["5px", "5px", "0", "0"]}
                        onClick={() => setSelectedImageIndex(index)}
                      >
                        <Wrapper css="overflow-x: auto;">
                          <ImgExtended
                            width="100%"
                            alt={image.alt}
                            fluid={image.fluid}
                          />
                        </Wrapper>
                      </FlexItem>
                    )}
                  </>
                )
              })}
            </Flex>
            <Flex
              width={["100%", "100%", "100%", "100%"]}
              flex={["0 1 1", "0 1 1", "1 0", "1 0"]}
              maxWidth={["100%", "100%", "100%", "100%"]}
              alignSelf="flex-start"
            >
              <Wrapper width="100%" ref={ref} maxWidth="1400px">
                <Media
                  url={location.pathname}
                  image_selected={images_group[selectedImageIndex]}
                />
              </Wrapper>
            </Flex>
          </Flex>
        </Flex>
      </Layout>
      <script async defer src="//assets.pinterest.com/js/pinit.js" />
    </>
  )
}

export default Project

Project.propTypes = {
  data: PropTypes.shape({
    prismicProject: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
      prismicProject(uid:{eq: $uid}, lang: {eq: "en-us"}) {              
        uid
        data {
          title {
            text
            html
          }
          meta_description
          meta_title
          canonical
          content {
            html
            text
          }
          #image_mosaic
          images_group {
            content {
              html
              text
            }
            image{
              url
              alt
              fluid(maxWidth: 1400) {
                ...GatsbyPrismicImageFluid
              }
            }
            video {
              url
              embed_url
            }
          }
        }

        
        
      }
    
  }
`
