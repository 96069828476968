import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  faTwitter,
  faFacebookF,
  faWhatsapp,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Wrapper = styled.span`
  padding: 0 0 10px 8px;
`;

const SocialShare = ({ media, description, url }) => {
  return (
    <>
      <Wrapper>
        <TwitterShareButton title={description} hashtags={['ArikLevy']} url={url}>
          <FontAwesomeIcon icon={faTwitter} fill="white" />
        </TwitterShareButton>
      </Wrapper>
      <Wrapper>
        <WhatsappShareButton url={url}>
          <FontAwesomeIcon icon={faWhatsapp} fill="white" />
        </WhatsappShareButton>
      </Wrapper>
      <Wrapper>
        <PinterestShareButton media={media} description={description} url={url}>
          <FontAwesomeIcon icon={faPinterestP} fill="white" />
        </PinterestShareButton>
      </Wrapper>
      <Wrapper>
        <FacebookShareButton hashtag="ArikLevy" url={url}>
          <FontAwesomeIcon icon={faFacebookF} fill="white" />
        </FacebookShareButton>
      </Wrapper>
      <Wrapper>
        <EmailShareButton subject={description} url={url}>
          <FontAwesomeIcon icon={faEnvelope} fill="white" />
        </EmailShareButton>
      </Wrapper>
    </>
  );
};

export default SocialShare;
